$jiotype-font-path: '/assets/fonts/jiotype/';

@font-face {
    font-family: "JioType";
    src: local(JioType Light), url('#{$jiotype-font-path}JioType-Light.eot');
    src: url("#{$jiotype-font-path}JioType-Light.eot?#iefix") format('embedded-opentype'),
         url("#{$jiotype-font-path}JioType-Light.woff2") format("woff2"),
         url("#{$jiotype-font-path}JioType-Light.woff") format("woff"),
         url("#{$jiotype-font-path}JioType-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "JioType";
    src: url('#{$jiotype-font-path}JioType-Medium.eot');
    src: url("#{$jiotype-font-path}JioType-Medium.eot?#iefix") format('embedded-opentype'),
         url("#{$jiotype-font-path}JioType-Medium.woff2") format("woff2"),
         url("#{$jiotype-font-path}JioType-Medium.woff") format("woff"),
         url("#{$jiotype-font-path}JioType-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "JioType";
    src: url('#{$jiotype-font-path}JioType-Bold.eot');
    src: url("#{$jiotype-font-path}JioType-Bold.eot?#iefix") format('embedded-opentype'),
         url("#{$jiotype-font-path}JioType-Bold.woff2") format("woff2"),
         url("#{$jiotype-font-path}JioType-Bold.woff") format("woff"),
         url("#{$jiotype-font-path}JioType-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "JioType";
    src: url('#{$jiotype-font-path}JioType-Black.eot');
    src: url("#{$jiotype-font-path}JioType-Black.eot?#iefix") format('embedded-opentype'),
         url("#{$jiotype-font-path}JioType-Black.woff2") format("woff2"),
         url("#{$jiotype-font-path}JioType-Black.woff") format("woff"),
         url("#{$jiotype-font-path}JioType-Black.ttf") format("truetype");
    font-weight: 900;
}
