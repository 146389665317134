body.login-page {
  /*background: url('/assets/img/bg/login-bg.jpg')no-repeat;
  background-size: cover;
  background-position: center;*/
  background: #FFF;
  overflow: hidden !important;
  &.registration{
    @media only screen and (max-width: 991px) {
      overflow: auto !important;
      .reg-form{
        height: auto !important;
      }
    }
    #carouselExampleIndicators{
      height:100vh;
      width:100%;
      img{
        height: 100vh;
      }
    }
    .reg-form{
      height: 400px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  app-header,
  app-sidenav {
    display: none;
  }

  main {
    padding-top: 0;
    padding-left: 0;
  }

  app-login {
    width: 100%;
  }

  h3{
    color: #000;
    font-family: 'JioType';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: normal;
    text-align: center;
    letter-spacing: normal;
    text-transform: uppercase;
    span{
      display: block;
      font-size: 20px;
      text-transform: none;
    }
  }

  p{
    color: #000;
    font-size: 13px;
    font-family: "JioType";
    font-weight: 300;
    a{
      color: #283287;
      font-weight: 700;
      text-decoration: none;
    }
  }
}

 /*
 New Css For Login Page 
 Date: 17-aug-2023
 */

.mat-tab-label{
  opacity: 1 !important;
}

.mat-tab-label .mat-tab-label-content{
  color: #141414;
  font-size: 14px;
  font-family: "JioType";
  font-weight: 500;
}

.mat-ink-bar{
  background-color: #FFDA00 !important;
}
.form-group {
  margin: 0;

  .mat-mdc-form-field-icon-suffix {
    color: #777;
    padding-right: 10px;
  }
}

button.mdc-button.mat-mdc-button.mat-primary.mat-mdc-button-base {
  border: 1px solid #283287 !important;
  color: #FFF!important;
  font-family: 'JioType';
  padding-left: 16px;
  padding-right: 16px;
  background: #283289;
  width: 95%;
}

.mat-mdc-dialog-surface.mdc-dialog__surface select {
  padding: 8px 22px;
  border-color: #d2d2d2;
  border-radius: 6px;
  width: 100%;
  padding-left:8px;
}

.mat-mdc-dialog-surface.mdc-dialog__surface select:focus-visible {
  border-color: #d2d2d2 !important;
  border: 1px solid  #d2d2d2 !important;
  outline: none;
  box-shadow: none;
}

.mat-mdc-dialog-surface.mdc-dialog__surface select.ng-valid.ng-touched.ng-dirty{
  padding: 8px 22px;
  border-color: #d2d2d2;
  border-radius: 6px;
  width: 100%;
  padding-left: 8px;
}



h2#mat-mdc-dialog-title-0 {
  font-size: 20px;
  font-family: 'JioType';
  text-align: center;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  min-width: 320px !important;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 10px;
  overflow: initial;
}
// .role-selection{
//   .mat-mdc-dialog-surface.mdc-dialog__surface {
//     padding-left: 0px !important;
//   }
// }
#mat-mdc-dialog-0 .mdc-dialog__container .mat-mdc-dialog-surface.mdc-dialog__surface {
  padding-left: 0px;
}

mat-dialog-content.mat-mdc-dialog-content.mdc-dialog__content.ng-star-inserted {
  padding-right: 10px;
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 67%);
}


h2#mat-mdc-dialog-title-0 img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 56px;
  margin-bottom: 10px;
  margin-top: -80px;
  border-radius: 100px;
  box-shadow: #00000017 1px 2px 3px 3px;
}
option.ng-star-inserted{
  font-family: 'JioType';
  text-transform: capitalize;  
}