
$one: #f5f5f5;
$two: #4285f4;
$three: #fbbc05;
$four: #34a853;
$five: #ea4335;

.loader {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100px;
  width: auto;
  position: relative;
}

@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}


.ball-pulse-sync {
  display: table;
  margin: 0 auto;
  background: url('/assets/img/loader.png');
  width: 50px;
  height: 50px;
  background-size: cover;
  animation: spin 0.8s linear infinite;
  background-repeat: no-repeat;
}

// .ball-pulse-sync > div:nth-child(1) {
//   -webkit-animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
//   animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
//   background: $five;
// }

// .ball-pulse-sync > div:nth-child(2) {
//   -webkit-animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
//   animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
//   background: $two;
// }

// .ball-pulse-sync > div:nth-child(3) {
//   -webkit-animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
//   animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
//   background: $four;
// }

.ball-pulse-sync > div {
  width: 15px;
  height: 15px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border-radius: 100%;
  margin: 2px;
  display: inline-block;
}
#pre-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.356);
  z-index: 10000;
}

.spinner {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100px;
  width: auto;
  position: relative;
}
.buttonspinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
  }