@import "../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import '../src/assets/scss/main.scss';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.test {
  color: #000;
}

.mdc-tab-indicator--active {
  background: #283287;
  border-radius: 5px 5px 0px 0px;
}

.mdc-tab-indicator--active span {
  color: #fff;
}

.pagination-sm {
  gap: 2px;
  display: flex !important;
  justify-content: center !important;
}

.page-link.active,
.active>.page-link {
  background-color: #98CA3F;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.page-link {
  background: none;
  border: 0px;
  color: #283287;
}
a.page-link:hover {
  background: #2831873f;
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
  border: 0px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
  background: none;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-family: 'Jiotype';
}

.pagination-sm {
  --bs-pagination-padding-x: 18px;
}

.pagination {
  --bs-pagination-disabled-bg: none;
}

.alert.alert-dismissible.alert-warning {
  padding: 0px;
  background: none;
  border: 0px;

  span {
    color: rgb(217, 48, 37);
    font-size: 13px;
    padding-left: 2px;
  }
}

/* CSS to style the pagination buttons as circles */
.pagination-page.page-item.ng-star-inserted a.page-link:hover,
.pagination-page.page-item.ng-star-inserted a.page-link:focus {
  border-radius: 50%;
  /* Makes the button circular */
}

/* Optional: Add transitions for a smooth hover effect */
.pagination-page.page-item.ng-star-inserted a.page-link {
  transition: border-radius 0.2s ease-in-out;
  /* Adjust the duration as needed */
}


.mdc-tab.mat-mdc-tab {
  background: #f1f1f1a8 !important;
  border-radius: 5px 5px 0px 0px !important;
  border-right: 1px solid #80808075;
}

.mdc-tab.mat-mdc-tab:hover {
  background: #f1f1f1a8 !important;
}

.mdc-tab.mat-mdc-tab.mdc-tab-indicator--active {
  background: #98CA3F !important;
  border-bottom: 1px solid #98CA3F;
}

.mdc-tab.mat-mdc-tab {
  border-top-right-radius: 70px !important;
  border-bottom-left-radius: 70px !important;
  overflow: hidden;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #98CA3F !important;
}

.mdc-tab.mat-mdc-tab:nth-child(2) {
  margin-left: -5px;
}

.mdc-tab.mat-mdc-tab:nth-child(3) {
  margin-left: -5px;
}

.mdc-switch__shadow {
  display: none !important;
}

.mdc-switch__track {
  height: 22px !important;
  border-radius: 18px !important;
}

//Authod Rahul 14 Sept 2023
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: #283287 !important;
}

.mdc-switch__icons {
  background: #fff;
  border-radius: 15px;
  height: 145% !important;
  position: relative;
  width: 145% !important;
  top: -20%;
  z-index: 1;
}

// Author Rahul on 27-10-2023
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__handle::after {
  background: var(--mdc-switch-disabled-unselected-handle-color, #424242);
  height: 145%;
  top: -2px;
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::after {
  background: var(--mdc-switch-disabled-selected-handle-color, #424242);
  height: 145%;
  top: -2px;
}
// Author Rahul on 27-10-2023
.mdc-switch__ripple {
  display: none !important;
}

.mdc-switch__handle {
  border-radius: 50% !important;
  width: 18px !important;
  height: 12px !important;
}

.mdc-switch__icon {
  display: none;
}
.mdc-switch--unselected{
  .mdc-switch__handle-track{
    top: -0.5px !important;
    left: 2px !important;
    height: 103% !important;
  }
}

.mdc-switch__handle-track{
  transition: opacity 0.4s ease, transform 0.4s ease !important;
}

.mdc-switch--selected {
  .mdc-switch__handle-track{
    top: -0.5px!important;
    left: -0.5px!important;
    height: 103% !important;
  }
}

.mdc-switch--unselected{
  .mdc-switch__handle-track{
    top: -0.5px !important;
    left: 2px !important;
    height: 103% !important;
  }
}
.mdc-switch--selected {
  .mdc-switch__handle-track{
    top: -0.5px!important;
    left: -0.5px!important;
    height: 103% !important;
  }
}

.mat-mdc-slide-toggle .mdc-switch {
  width: var(--mdc-switch-track-width, 39px) !important;
}

//Authod Rahul 14 Sept 2023

// Updated table border
.theme-table {
  border: 0px solid #E4E4E4 !important;
}

.table-responsive {
  border-radius: 10px;
  border: 1px solid #E4E4E4;
  margin-bottom: 10px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

table.table.theme-table.table-striped.text-center.ng-star-inserted {
  margin-bottom: 0px;
}

// Updated table border Author Rahul 21 Sept 2023
a {
  cursor: pointer;
}

.modal-backdrop {
  &.show {
    display: contents;
  }
}

// Author Rahul on 04-10-2023
// .modal{    z-index: 1000;}.modal-backdrop{    &.show{        display: contents;    }}
.modal {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.477);
}

.modal-backdrop {
  &.show {
    display: contents;
  }
}
// Author Rahul on 04-10-2023
button{
    font-family: 'JioType' !important;
    letter-spacing: normal !important;
}
.cdk-overlay-pane{
    max-width: 100% !important;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: inherit;
}
.mdc-dialog .mdc-dialog__surface {
    border-radius: 15px !important;
    width: fit-content;
}
// Author Rahul on 11-10-2023
.cdk-global-overlay-wrapper{
    overflow-y: scroll;
}
@media only screen and (max-width:768px) {
    .mat-mdc-dialog-container {
        transform: scale(0.9);
    }
}

label{

  pointer-events: none !important;

}

input:read-only {

  color: grey !important;

}

.noHover{

  pointer-events: none;

}
// Card style according to figma design
.list-card{
  border: 0px !important;
  .card-body{
    border: 1px solid #E4E4E9;
    border-radius: 8px;
    bottom: 7px;
    background: white;
  }
  .card-header {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
}
// Card style according to figma design
input[type="checkbox"]{
  label{
    pointer-events: all !important;
  }
}

label{
  input[type="checkbox"]{
    pointer-events: all !important;
  }
}

.card-fcna{
  --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: .5rem;
    --bs-card-title-color: ;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: transparent;
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
    --bs-card-cap-padding-y: .5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), .03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: .75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    color: var(--bs-body-color);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.09);
}
// Customized Global scrollbar
// ::-webkit-scrollbar {
//   width: 6px;
// }


// ::-webkit-scrollbar-thumb {
//   background-color: #283287;
//   border-radius: 6px;
// }


// ::-webkit-scrollbar-track {
//   background-color: #f1f1f1;
// }
// Customized Global scrollbar
// .cdk-overlay-container
// 
// app-contract-add {
  .cdk-global-overlay-wrapper{
  //  pointer-events: auto !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
// }

label#mat-mdc-form-field-label-2 {
  padding-right: 29px;
}

.mat-mdc-standard-chip{
  background-color: #ffffff !important;
  border: 1px solid #283287!important ;
}
span.mdc-evolution-chip__text-label {
  color: #283287 !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 47px;
  height: 43px;
  display: flex;
  padding: 12px;
  align-items: center;
}

.remarks_link{
  color: #293297 !important;
}
.pagination-first, .pagination-prev, .pagination-next, .pagination-last{
  a.page-link {
    font-size: 20px; 
    font-family: monospace;
    border-radius: 4px;
  }
  
}

.pagination-page{
  .page-link{
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}

.mat-mdc-option .mdc-list-item__primary-text{
  white-space: inherit !important;
}
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #283287;
  border: 1.5px solid #98CA3F;
}