body {
  background: #FFF;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
}

main {
  padding-left: 0;
  padding-top: 108px;
  padding-bottom: 55px;
  // height: 100%;

  .breadcrumb{
    margin: 0;
    .breadcrumb-item{
      color: #000;
      font-size: 12px;
      font-weight: 500;
      a{
        color: #999;
        text-decoration: none;
      }
    }
  }

  .sub-heading-lg{
    color: #1F3B90;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
  }

  .sub-heading{
    color: #000;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
  }

  .section-bg-light{
    background: #F5F5F5;
    border: 1px solid #E4E4E4;
  }
  
  .btn-theme-fill-blue{
    padding-left: 15px !important;
    padding-right: 15px !important;
    color:#FFF !important;
    font-size: 14px;
    font-family: "JioType";
    font-weight: 500;
    height: 48px !important;
    background: #283287;
    letter-spacing: normal;
    border: 1px solid #283287;
    border-radius: 6px;
    &:hover{
      color: rgba(255, 255, 255, .6) !important;
      background: #283287 !important;
      border: 1px solid #283287;
    }
    &:disabled{
      background-color: #283287;
    }
  }

  .btn-theme-outline-blue{
    padding-left: 15px !important;
    padding-right: 15px !important;
    color:#283287 !important;
    font-size: 14px;
    font-family: "JioType";
    font-weight: 500;
    height: 48px !important;
    background: transparent;
    letter-spacing: normal;
    border: 1px solid #283287 !important;
    border-radius: 6px;
    &:hover{
      color: #000 !important;
      //background: #283287;
    }
  }

  .file-upload{
    .form-control,
    .form-control[type=file]{
      line-height: 43px;
      border-color: rgba(0, 0, 0, 0.349);
    }
  }

  .file-upload-list{
    .upload-list{
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        padding: 10px 0;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        border-bottom: 1px solid rgba(204,204,128,.5);
        img{
          margin-right: 20px;
        }
        .btn{
          padding: 0;
          display: inline-block;
          background: transparent;
          text-align: center;
          border-color: #b2b2b2;
          border-radius: 50%;
          img{
            margin: 0;
          }
          &:hover,
          &:focus{
            background: #FFF;
          }
        }
      }
    }
  }

  .scan-qr{
    h3{
        margin: 0 0 20px;
        color: #000;
        font-size: 20px;
        font-weight: 900;
        line-height: 30px;
    }
    p{
      margin: 10px 0 20px;
      padding: 0;
      color: #333;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .info{
    position: relative;
    padding: 20px;
    background: #FFF;
    min-height: 90px;
    border-radius: 8px;
    h4{
        margin: 0;
        color: #000;
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        span{
          margin-top: 3px;
          display: block;
          color: #333;
          font-size: 12px;
          line-height: 13px;
          font-weight: 500;
        }
    }
    p{
        margin: 5px 0 0;
        color: #666;
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
    }
    .digit{
      position: absolute;
      right: 20px;
      bottom: 26px;
      color: #000;
      font-size: 13px;
      font-weight: 700;
      line-height: 16px;
    }
    &.info-bg1{
      background: #FFF4DE;
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
    &.info-bg2{
      background: #DCFCE7;
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
    &.info-bg3{
      background: #DCF6FF;
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
  }

  .card{
    .card-header{
      padding-top: 16px;
      padding-bottom: 16px;
      color: #000 ;
      font-size: 18px;
      font-weight: 700;
      background: #eee;
      .card-title{
        color: #000;
        font-size: 20px;
        font-weight: 700;
      }
    }
    .card-sub-header{
      background: #eee;
    padding: 15px;
    font-size: 16px;
    color: #333;
    font-weight: 500;
      .card-title{
        padding-left: 10px;
        color: #000;
        font-size: 12px;
        font-weight: 400;
      }
    }
    .no-record {
      float: left;
      color: #666;
      font-size: 16px;
      padding-left: 30px;
      padding-right: 30px;
      line-height: 16px;
      margin-top: 25px;
      margin-left: 30px;
      padding-bottom: 20px;
  }
    .card-body{
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .custom-tabs{
    .nav-item{
      .nav-link{
        position: relative;
        color: #999;
        font-size: 14px;
        font-weight: 500;
        border: none;
        &:hover,
        &.active{
          color: #000;
          font-weight: 700;
          &::before{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            height: 2px;
            width: 100%;
            background: #1F3B90;
          }
        }
      }
    }
  }

  .theme-table{
    border: 1px solid #E4E4E4;
    thead{
      tr{
        th{
          padding: 15px;
          color: #000;
          font-size: 13px;
          font-weight: 700;
          line-height: 16px;
          background: #F3F4FE; //Authod Rahul 14 Sept 2023
        }
      }
    }
    tbody{
      tr{
        td{
          padding: 15px;
          color: #333;
          font-size: 13px;
          font-weight: 700;
          line-height: 16px;
          a{
            color: #1F3B90;
            text-decoration: none;
          }
        }
      }
    }
    &.table-striped {
      tbody {
        tr:nth-of-type(odd){
          * {
            --bs-table-bg-type: var(--bs-table-bg);
          }
        }
        tr:nth-of-type(even){
          * {
            --bs-table-bg-type: #FAFAFA;  //Authod Rahul 14 Sept 2023
          }
        }
      }
    }
  }
}

 

.otp-time{
  p{
      margin: 0;
      color: #666 !important;
      font-size: 12px;
      font-weight: 500 !important;
      a{
        color: #00A651 !important;
        text-decoration: none;
      }
  }
}

.mat-mdc-form-field {
  display: block;
  width: 100%;
  color: #000 !important;
  font-size: 14px;
  font-family: "JioType";
  font-weight: 500;
  .mdc-floating-label--required{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .mat-mdc-text-field-wrapper{
    background: #FFF;
    border-radius: 4px;
    .mdc-text-field--outlined{
      .mdc-floating-label--float-above{
        --mat-mdc-form-field-label-transform: translateY( -28.75px) !important;
      }
    }
    &.mdc-text-field--disabled{
      .mat-mdc-floating-label{
        color: rgba(0, 0, 0, .5) !important;
      }
    }
    .mat-mdc-form-field-flex{
      .mdc-notched-outline{
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing{
          border-color: #e4e4e4;
        }
      }
      .mat-mdc-floating-label{
        top: 20px;
        color: #000;
        font-size: 13px;
        font-family: "JioType";
        font-weight: 500;
        &.mdc-floating-label--float-above{
          --mat-mdc-form-field-label-transform: translateY( -26.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75))
        }        
      }
    }
  }

  .mat-mdc-form-field-infix{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    min-height: 48px;
    .mdc-text-field__input{
      color: #000;
    }
    .mat-mdc-select{
      .mat-mdc-select-trigger{
        .mat-mdc-select-value{
          .mat-mdc-select-value-text{
            .mat-mdc-select-min-line{
              // color: #000;
              font-size: 13px;
              font-family: "JioType";
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .mat-mdc-form-field-label-wrapper{
    top: -1.3em;
    .mdc-notched-outline{
      height: 50px;
    }
  }

  .mat-mdc-form-field-icon-suffix{
    mat-icon{
      position: relative;
      img{
        position: absolute;
        top: 14px;
        left: 14px;
      }
    }
  }
}
.button-hover{
  &:hover{
      color: rgba(255, 255, 255, .6) !important;
      background: #283287 !important;
      border: 1px solid #283287;
    }
}

.btn-dialog-okButton {
  padding-left: 15px !important;
    padding-right: 15px !important;
    color:#FFF !important;
    font-size: 14px;
    font-family: "JioType";
    font-weight: 500;
    height: 48px !important;
    background: #283287;
    letter-spacing: normal;
    border: 1px solid #283287;
    border-radius: 6px;
    &:hover{
      color: rgba(255, 255, 255, .6) !important;
      background: #283287 !important;
      border: 1px solid #283287;
    }
    &:disabled{
      background-color: #283287;
    }
}
.btn-dialog-canceledBtn {
  padding-left: 15px !important;
    padding-right: 15px !important;
    color:#283287 !important;
    font-size: 14px;
    font-family: "JioType";
    font-weight: 500;
    height: 48px !important;
    background: transparent;
    letter-spacing: normal;
    border: 1px solid #283287 !important;
    border-radius: 6px;
    &:hover{
      color: #000 !important;
}
}

